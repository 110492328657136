/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
// import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, image }) {
  // const { site } = useStaticQuery(graphql`
  //   query {
  //     site {
  //       siteMetadata {
  //         title
  //         description
  //         author
  //         image
  //         siteUrl
  //       }
  //     }
  //   }
  // `)
  
  // this throws an error:
  // title = title || site.siteMetadata.title;
  // const metaDescription = description || site.siteMetadata.description;
  // image = `${site.siteMetadata.siteUrl}${image || site.siteMetadata.image}`;
  // const author = site.siteMetadata.author;
  
  title = title || `Digital Excellence`;
  const metaDescription = description || `Wir erschaffen inspirierende, digitale Produkte.`;
  image = image || `/images/made-by-tickaroo-preview.png`;
  const author = `tickaroo`;


  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | made.by Tickaroo`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: image,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO



// const query = graphql`
//   query SEO {
//     site {
//       siteMetadata {
//         title
//         description
//         author
//         image
//         siteUrl
//       }
//     }
//   }
// `